import React, { useState, useEffect } from 'react'

const Testimonial = ({quoteList}) => {

    const [quoteNumber, setQuoteNumber] = useState(Math.floor(quoteList.length * Math.random()))

    useEffect(() => {

        setQuoteNumber( Math.floor(quoteList.length * Math.random()))

    }, [quoteList, quoteNumber])

    console.log(quoteList)
    return (

        <section className="testimonial">
            <div className="inner">
                <div className="bg-wrapper">
                    <blockquote>
      
                        <span>{quoteList[quoteNumber].quoteBody}</span>

                        <cite>- {quoteList[quoteNumber].quoteOrigin}</cite>
                    </blockquote>

                </div>
            
            
            

            </div>
        </section>
       
    )

}

export default Testimonial