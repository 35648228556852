import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Testimonial from '../components/testimonial'

const PageTemplate = ({data}) => {
    const { frontmatter, html } = data.markdownRemark

    const headSource = [
        frontmatter.hero.childImageSharp.big,
        {
            ...frontmatter.hero.childImageSharp.medium,
            media: `(max-width: 62.5em) and (min-width: 40.0625em)`
        },
        {
            ...frontmatter.hero.childImageSharp.small,
            media: `(max-width: 40em)`
        },
    ]

    const imageOneSource = [
        frontmatter.imageOne.childImageSharp.fluid, 

    ];

    const imageTwoSource = [
        frontmatter.imageTwo.childImageSharp.fluid, 

    ];
    return (

        <Layout pageClass={`Page`}>
        <SEO 
            title={frontmatter.title} 
            description={frontmatter.metaDescription} 
            />
            <div className="page-heading">
                <Img 
                    fixed={headSource}
                    alt={frontmatter.title}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    style={{
                        width:'100%',
                        display:'block'
                    }}
                    
                />
                <div className="image-overlay">
                    <div>
                        <h1>{frontmatter.title}</h1>
                        <div className="header-underline"></div>
                    </div>
                </div>
            </div>
            <div className="top-strip "></div>
            <main className='main'>
               
                <div className='typography inner'>
                    <div className="page-content"   dangerouslySetInnerHTML={{__html: html}}>
                        
                    </div>
                    <div className="page-images">

                        {frontmatter.imageOne ?
                            <Img 
                                fluid={frontmatter.imageOne.childImageSharp.fluid} 
                            />
                            :
                            ''    
                        }
                        
                        {frontmatter.imageTwo ?
                            <Img 
                                fluid={frontmatter.imageTwo.childImageSharp.fluid} />
                            :
                            ''
                        }
                        
                    </div>
                </div>
            </main> 
            {frontmatter.quoteList.length > 0 ? 
                <Testimonial 
                    quoteList={frontmatter.quoteList}
                />: ''

            }            
        </Layout>
    
    )
}
export const pageQuery = graphql`
  query($url: String!) {
    markdownRemark(frontmatter: { path: { eq: $url } }) {
        frontmatter {
            title
            metaDescription
            path
            hero {
                childImageSharp {
                    big: fixed (width: 2000, height: 390, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                    medium: fixed (width: 1000, height: 390, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                    small: fixed (width: 480, height: 390, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            imageOne {
                childImageSharp {
                    fluid (maxWidth: 460, quality: 100) {
                        ...GatsbyImageSharpFluid
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            imageTwo {
                childImageSharp {
                    fluid (maxWidth: 460, quality: 100) {
                        ...GatsbyImageSharpFluid
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            quoteList {
                quoteBody
                quoteOrigin
            }
            
        }
        html
    }
  }

`

export default PageTemplate